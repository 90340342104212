import React, { useState } from "react"
import { Link } from "gatsby"

import QRCode from "qrcode.react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import styled from "styled-components"
import * as v from "../components/variables"

import Header from "../components/Header"
import NavList from "../components/NavList"
import NavListItem from "../components/NavList/item"

import LogoIcon from "../images/am-icon.png"

const Container = styled.div`
  ${v.grid}
`

const Logo = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  ${v.grid};

  img {
    grid-column: main;
    margin-top: ${v.baseline(2)};
    height: ${v.baseline(3)};
  }

  &:after {
    content: "";
    display: block;
    grid-column: full;
  }
`

const QRPreview = styled(QRCode)``

const Content = styled.div`
  grid-column: main;
  display: grid;
  grid-gap: inherit;
`

const Calculator = styled.form`
  grid-template-columns: [full-start] 1fr 1fr [full-end];

  label {
    margin-bottom: ${v.baseline()};
    display: block;
  }

  input {
    ${v.reset};
    ${v.text.a};
    width: fit-content;
    text-indent: 1ex;
    border-bottom: 2px solid rgb(0 0 0 / 0.2);
    width: 100%;

    &:focus {
      border-color: ${v.purple};
    }
  }
`

const IndexPage = ({ data }) => {
  const [url, setURL] = useState("armstrongmedical.net")

  return (
    <Layout>
      <SEO title="QR Code Generator" />
      <Container>
        <Header title="QR Code Generator">
          <Logo>
            <img src={LogoIcon} />
          </Logo>
          <p>
            Type or paste in the URL you want to link to, then right click on
            the QR code and select "Save image as..."
          </p>
        </Header>
        <Content>
          <Calculator>
            <label htmlFor="url">URL</label>
            <input
              type="text"
              value={url}
              name="url"
              onChange={e => setURL(e.target.value)}
              placeholder="http://..."
            ></input>
          </Calculator>
          <QRPreview
            value={url}
            size={240}
            fgColor={v.purple}
            imageSettings={{
              src: LogoIcon,
              x: null,
              y: null,
              height: 42,
              width: 42,
              excavate: true,
            }}
          ></QRPreview>
        </Content>
      </Container>
    </Layout>
  )
}

export default IndexPage
